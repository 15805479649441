import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  Iconsvg,
} from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import {
  ICreateModuleOverviewNames,
  IModuleParameterItem,
} from 'interfaces/modules/module.interface';
import React, { useCallback, useMemo, useState } from 'react';
import {
  UseFormWatch,
  FieldValues,
  UseFormReset,
  UseFormHandleSubmit,
} from 'react-hook-form';
import {
  clearState,
  selectParameterList,
} from 'redux/createModule/CreateModuleSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';

const requiredFields: ICreateModuleOverviewNames[] = [
  'moduleName',
  'artifactId',
  'description',
  'authorName',
];

interface ICancelCreateModuleFooter {
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  handleSubmit: UseFormHandleSubmit<any>;
}

const CancelCreateModuleFooter = ({
  watch,
  reset,
  handleSubmit,
}: ICancelCreateModuleFooter) => {
  const [isLoading] = useState<boolean>(false);
  const modalInfo = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const parameterList = useAppSelector(
    selectParameterList
  ) as IModuleParameterItem[];
  const allFields = watch(requiredFields);

  const areAllFieldsFilled = useMemo(
    () =>
      requiredFields.every(
        (field, index) => allFields[index] && allFields[index].trim() !== ''
      ),
    [allFields, requiredFields]
  );

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      reset();
      dispatch(clearState());
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  const onSubmit = (
    onSubmit: any
  ): React.MouseEventHandler<HTMLButtonElement> | undefined => {
    throw new Error('Function not implemented.');
  };

  const checkCreateCodeStatus = useCallback(() => {
    return parameterList.some((param) => {
      const isParamIdInvalid = !param.paramId;
      const isTypeInvalid = !param.type;
      const isParamNameInvalid = !param.paramName;
      const isValueInvalid = param.type === 'ENUM' && !param.value;
      const isValuesInvalid = param.type === 'ENUM' && !param.values;
      const isEnumValueInvalid =
        (param.dependsOn && !param.enumValue) ||
        (param.enumValue && !param.dependsOn);

      return (
        isParamIdInvalid ||
        isTypeInvalid ||
        isParamNameInvalid ||
        isValueInvalid ||
        isValuesInvalid ||
        isEnumValueInvalid
      );
    });
  }, [parameterList]);

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'} iconPosition="left">
            <button
              type="button"
              data-testid="SubmitButton"
              id="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={
                checkCreateCodeStatus() || !areAllFieldsFilled || isLoading
              }
            >
              <Iconsvg name="bolt" />
              <span>Create code</span>
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default CancelCreateModuleFooter;
