import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './ParametersDetailsColumn.module.scss';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  addParameter,
  deleteParameter,
  selectParameterList,
  selectSelectedParameter,
  updateParameterProps,
} from 'redux/createModule/CreateModuleSlice';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';
import { ButtonField, Icon } from '@wk/components-v3-react16';
import { SelectField } from '@wk/components-react16';
import {
  CREATE_MODULE_DEFAULT_PARAMETER_NAME,
  DELETE_PARAMETER_MESSAGE,
  MODULE_PARAMETER_TYPES,
  SUCCESS_DELETE_PARAMETER_MESSAGE,
} from 'utils/common-constants';
import ParametersDetailsInputs from './components/ParametersDetailsInputs';
import ConfirmModal from 'components/Modals/overlayModals/ConfirmModal/ConfirmModal';
import { useState } from 'react';
import { showToastAndClose } from 'utils/commonFunctions/CommonFunctions';
import { Useuid } from 'utils/hooks/useUid';

const ParametersDetailsColumn = () => {
  const dispatch = useAppDispatch();
  const selectedParameter = useAppSelector(
    selectSelectedParameter
  ) as IModuleParameterItem;
  const parameterList = useAppSelector(
    selectParameterList
  ) as IModuleParameterItem[];
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const createConfirmContent = () => {
    const dependedParams = parameterList.filter(
      (param) =>
        param?.dependsOn && param.dependsOn === selectedParameter.paramId
    );
    return (
      <div>
        <p className={style['confirm-description']}>
          {DELETE_PARAMETER_MESSAGE}
        </p>
        <ul>
          {dependedParams.map((param) => (
            <li key={param.id}>{param.paramName}</li>
          ))}
        </ul>
      </div>
    );
  };

  const handleSelect = (event: any) => {
    dispatch(
      updateParameterProps({
        id: selectedParameter.id,
        newSelected: { ...selectedParameter, type: event.target.value },
      })
    );
  };

  const handleDelete = () => {
    setIsConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteParameter({
        id: selectedParameter.id,
        param: selectedParameter,
      })
    );
    showToastAndClose('success', 'informationToast', () => {}, dispatch, {
      text: SUCCESS_DELETE_PARAMETER_MESSAGE,
    });
    setIsConfirmOpen(false);
  };

  const handleDuplicate = () => {
    const copySufix = selectedParameter.paramName?.includes('(copy)')
      ? ''
      : '(copy)';
    const copyName = selectedParameter?.paramName
      ? `${selectedParameter.paramName} ${copySufix}`
      : `${CREATE_MODULE_DEFAULT_PARAMETER_NAME} ${copySufix}`;

    dispatch(
      addParameter({
        ...selectedParameter,
        id: `${selectedParameter.id}-copy-${Useuid()}`,
        paramName: copyName.trim(),
      })
    );
  };

  return (
    <CsbErrorBoundary>
      <div data-testid="ParametersDetailsColumn" className={style['container']}>
        {selectedParameter?.id && (
          <>
            <div className={style['actions-container']}>
              <ButtonField type="icon">
                <button
                  data-testid="duplicate-param-button"
                  type="button"
                  className={style['action-button']}
                  onClick={handleDuplicate}
                >
                  <Icon name="wk-icon-folder-from"></Icon>
                  <span>Duplicate parameter</span>
                </button>
              </ButtonField>
              <ButtonField type="icon">
                <button
                  type="button"
                  className={style['action-button']}
                  onClick={handleDelete}
                >
                  <Icon name="wk-icon-trash"></Icon>
                  <span>Delete</span>
                </button>
              </ButtonField>
            </div>
            <div className={style['select-input']}>
              <SelectField label="Parameter type" labelFor="paramSelect">
                <select
                  id="paramSelect"
                  name="paramSelect"
                  value={(selectedParameter.type as string) || ''}
                  onChange={handleSelect}
                >
                  <option value="">Select parameter type</option>
                  {MODULE_PARAMETER_TYPES.map(({ label, value }) => (
                    <option key={label} value={value} label={label}>
                      {label}
                    </option>
                  ))}
                </select>
              </SelectField>
            </div>
            {selectedParameter?.type && (
              <ParametersDetailsInputs
                selectedParameter={selectedParameter}
                parameterList={parameterList}
              />
            )}
            {!selectedParameter?.type && (
              <div className={style['info-container']}>
                <span className={style['info-title']}>
                  Info about parameter type
                </span>
                {MODULE_PARAMETER_TYPES.map(({ label, description }) => (
                  <div key={label}>
                    <span className={style['info-subtitle']}>{label}</span>
                    <p className={style['info-description']}>{description}</p>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        content={createConfirmContent()}
        onConfirm={handleConfirmDelete}
        confirmLabel="Delete"
      />
    </CsbErrorBoundary>
  );
};

export default ParametersDetailsColumn;
